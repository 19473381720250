export const features: FeatureGroup[] = [
    {
        name: 'Features',
        link: '',
        anchors: []
    },
    {
        name: 'Simulation Results',
        link: 'results',
        anchors: ['KPIs of the Scenario', 'Cost Distribution', 'Resource Utilization', 'MXML Logs']
    },
    {
        name: 'Simulation Parameters',
        link: 'parameters',
        anchors: [
            'Business Process Model',
            'Simulation Scenario',
            'Resource Pool',
            'Parameters for BPMN Elements',
            'Duration Distributions'
        ]
    },
    {
        name: 'BPMN 2.0 Support',
        link: 'bpmn',
        anchors: ['Process', 'BPMN Quick Guide']
    }
]

export interface FeatureGroup {
    name: string
    link: string
    anchors: string[]
}
