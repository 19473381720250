import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import { PageHeader } from '../components/PageHeader'
import { FeatureGroup, features } from '../components/features-menu'

interface FeatureGroupProps extends FeatureGroup {
    location: any
}

const FeatureGroupMenu = (props: FeatureGroupProps) => {
    const { name, link, anchors, location } = props
    const pathname = `/features/${link}`

    const groupClass = !location.hash && location.pathname === pathname ? 'is-active' : undefined

    return (
        <>
            <p className="menu-label">
                <Link to={pathname} className={groupClass}>
                    {name}
                </Link>
            </p>
            <ul className="menu-list">
                {anchors.map(anchor => {
                    const hash = '#' + anchor.toLowerCase().replace(/\s/g, '-')
                    const target = `${pathname}${hash}`

                    const linkClass = location.hash === hash && location.pathname === pathname ? 'is-active' : undefined
                    return (
                        <li key={hash}>
                            <Link to={target} className={linkClass}>
                                {anchor}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

const FeaturePage = ({ data, location }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <PageHeader image="/img/features-header.jpg" title="Features" />
            <section className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column is-narrow">
                            <aside className="menu menu-list">
                                {features.map(feature => (
                                    <FeatureGroupMenu key={feature.name} location={location} {...feature} />
                                ))}
                            </aside>
                        </div>
                        <div className="column">
                            <div className="content">
                                <h1>{post.frontmatter.title}</h1>
                                <HTMLContent content={post.html} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

FeaturePage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object
    })
}

export default FeaturePage

export const pageQuery = graphql`
    query FeatureByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
            }
        }
    }
`
